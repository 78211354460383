import { createStore } from 'vuex'

import user from './modules/user'
import task from './modules/task'
import nav from './modules/nav'
import trader from './modules/trader'

interface StateType {
  userinfo:(string | null)
}
export default createStore({
  state: {
    userinfo:sessionStorage.getItem('userinfo')?JSON.parse(sessionStorage.getItem('userinfo')||''):null
  },
  getters: {
    getUserInfo(state:StateType){
      return state.userinfo
    }
  },
  mutations: {
    REQ_USERINFO(state:StateType,payload:string | null){
      if(payload){
        sessionStorage.setItem('userinfo',JSON.stringify(payload))
        state.userinfo = payload
      }else{
        sessionStorage.removeItem('userinfo')
        sessionStorage.removeItem('nav_list')
      }
    }
  },
  actions: {
    changeUserInfoAction(context:any,payload:any){
      context.commit('REQ_USERINFO',payload)
    },
    getUserInfo() {
      return JSON.parse(sessionStorage.getItem('userinfo') as string).data
    }
  },
  modules: {
    user,
    task,
    nav,
    trader
  }
})
