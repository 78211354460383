import axios from "axios";
import router from '../router'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  baseURL: "/api",
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    if (config.headers['Content-Type'] == null) {
      config.headers["Content-Type"] = "application/json;charset=UTF-8"
    }
    if (localStorage.getItem("token")) {
      config.headers.Authorization = localStorage.getItem("token")
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.data.code == 500) {
      alert(response.data.msg)
      router.push('/login')
      localStorage.clear()
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;