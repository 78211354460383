import { getQueryUser } from "@/request/api";
interface StateType {
  userlist: Array<object>
  seite: Number
}
const state = {
  userlist: [],
  seite: 1,
  total: 0,
  isRoot: 0,
}

const getters = {
  getUserList(state: StateType) {
    return state.userlist
  }
}

const mutations = {
  REQ_USERLIST(state: StateType, payload: Array<object>) {
    state.userlist = payload
  },
  SAVE_USERINFO(state: any, value: any) {
    if (sessionStorage.getItem('userinfo') != null) {
      const user = JSON.parse(sessionStorage.getItem('userinfo') as string)
      state.isRoot = user.isRoot
    }
  }
}

const actions = {
  getUserListActions(context: any, value: any) {
    getQueryUser({
      seite: context.state.seite,
      param: value != null ? value.param : ""
    }).then((res: any) => {
      context.commit('REQ_USERLIST', res.data)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}