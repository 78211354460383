import axios from "axios";
// import qsString from 'querystring'
import router from '../router'

// let baseUrl = '/PromotionAppuni'
let baseUrl = '/api'

// axios请求拦截器
axios.interceptors.request.use(req => {
    let token = localStorage.getItem("token")
    if (token) {
        req.headers.Authorization = localStorage.getItem("token")
    }
    return req
})

// axios响应拦截器
axios.interceptors.response.use(res => {
    if(res.data.code==500){
        alert(res.data.msg)
        router.push('/login')
        localStorage.clear()
    }
    return res.data
})

export const get = (url: string, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(baseUrl + url, {
            params
        })
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}

export const post = (url: string, params: {}, isFile = false) => {
    let data: any = {}
    if (isFile) {
        data = new FormData()
        for (let [key, value] of Object.entries(params)) {
            data.append(key, value)
        }
    } else {
        data = params
    }
    return new Promise((resolve, reject) => {
        axios.post(baseUrl + url, data)
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}