import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import voucher from '@/views/task/voucher/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('../pages/login.vue')
  },
  {
    path: '/index',
    name: '首页',
    component: () => import('../pages/index.vue'),
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/home.vue')
      },
      // 权限管理系统
      {
        path: '/authorManage',
        name: '权限管理系统',
        redirect: 'basicAdmin',
        children: [
          {
            path: 'basicAdmin',
            component: () => import('../views/user/user.vue'),
            name: '基础配置管理',
            redirect: 'user',
            children: [
              {
                path: 'user',
                name: '账号管理',
                component: () => import('../views/user/user.vue')
              }
            ]
          }
        ]
      },
      // 任务管理
      {
        path: '/taskManage',
        name: '任务管理',
        children: [
          {
            path: 'task',
            name: 'app推广任务',
            component: () => import('../views/task/task.vue'),
          },
          {
            path: 'voucher',
            name: '凭证',
            component: voucher,
          },
          {
            path: 'cretificateAudit',
            name: '凭证审核',
            component: () => import("@/views/task/cretificateAudit/index.vue"),
          },
          {
            path: 'income',
            name: '收益统计',
            component: () => import("@/views/task/income/index.vue"),
          },
          {
            path: 'withdrawInfo',
            name: '提现信息',
            component: () => import("@/views/task/withdrawInfo/index.vue"),
          },
          {
            path: 'withdrawAudit',
            name: '提现审核',
            component: () => import("@/views/task/withdrawAudit/index.vue"),
          },
        ]
      },
      // 源码管理
      {
        path: '/sourcode',
        name: '源码管理',
        children: [
          {
            path: 'shop',
            name: '商品管理',
            component: () => import('../views/sourceCode/shop.vue'),
          },
          {
            path: 'user',
            name: '用户管理',
            component: () => import('../views/sourceCode/user.vue'),
          },
          {
            path: 'order',
            name: '订单管理',
            component: () => import('../views/sourceCode/order.vue'),
          },
          {
            path: 'fruit',
            name: '卡密信息',
            component: () => import('../views/sourceCode/fruit.vue'),
          }
        ]
      },
      // 商家任务管理
      {
        path: '/traderTask',
        name: '商家任务管理',
        children: [
          {
            path: 'Task',
            name: '商家任务',
            component: () => import('../views/traderTask/TaskManage.vue')
          },
          {
            path: 'Enter',
            name: '商家入驻',
            component: () => import('../views/traderTask/Enter.vue')
          },
          {
            path: 'traderExamine',
            name: '商家审核',
            component: () => import('../views/traderTask/traderExamine.vue'),
          },
          {
            path: 'taskExamine',
            name: '任务审核',
            component: () => import('../views/traderTask/taskExamine.vue'),
          }
        ]
      },
      {
        path: '/:path*',
        redirect: '/home'
      }
    ]
  },
  {
    path: '/:path*',
    redirect: 'index'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next()
  } else if (store.getters.getUserInfo) {
    next()
  } else {
    next('/login')
  }
})
export default router
