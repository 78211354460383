import { getTraderTaskList, addTask } from '@/request/api'

const state = {
    traderlist: [],
    page: 1,
    size: 10,
    total: 0,
    configList:{}
}

const getters = {
    getTaskList(state: any) {
        return state.traderlist
    },
    getTotal(state:any){
        return state.total
    }
}

const mutations = {
    // 设置显示商家任务列表
    setTraderTasks(state: any, payload: Array<Object>) {
        state.traderlist = payload
    },
    // 设置分页总数
    setTraderTotal(state:any,total:Number){
        state.total=total
    },
    // 切换页面
    setPage(state:any,page:Number){
        state.page=page
    },
    // 设置查询条件
    setConfig(state:any,config:any){
        state.configList=config
    }
}

const actions = {
    // 获取商家任务列表
    getTraderTasks(content: any,payload:any) {
        content.commit("setConfig",payload)
        getTraderTaskList({page:content.state.page,size:content.state.size,...state.configList}).then((res:any) => {
            content.commit('setTraderTasks', res.data.GameList)
            content.commit('setTraderTotal',res.data.total)
        })
    },
    //切换页
    changePage(context:any,payload:number){
        context.commit('setPage',payload)
        context.dispatch('getTraderTasks',state.configList)
    },
    // 新增任务
    AddTraderTask(content: any, params: any) {
        addTask(params).then(res => {
            console.log(res);
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}